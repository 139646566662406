body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-12 {
  font-size: 12px !important;
}

.logo-header .navbar-toggler .navbar-toggler-icon {
  color: #fff !important
}

.accordion .card-header {
  padding-left: 0 !important;
}

.card.fulfillment-group {
  border: solid 2px #ccc;
}

table.packaging tr.duplicate td {
  background: red;
}

mark {
  background-color: yellow;
}

.page-cso-account {
  background-color: orange;
}

.page-web-retail {
  background-color: #2A8947;
}

.daily-list .daily-list-header,.daily-list .daily-list-item {
  background-color: #fff;
  color: rgba(0,0,0,.6);
  margin-bottom: 1px
}

.daily-list .daily-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 20px;
  border-bottom: solid 1px #ccc;
}

.daily-list .daily-list-item:last-child {
  border-radius: 0 0 6px 6px;
  margin: 0
}

.daily-list .daily-list-item.has-alerts {
  border-left: 4px solid #ce2027;
  padding-left: 16px
}

.daily-list .daily-list-item .date {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 12px;
  margin-right: 20px;
  min-width: 45px;
  text-align: center;
  white-space: nowrap;
  width: 45px;
}

.daily-list .daily-list-item .date .day {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase
}

.daily-list .daily-list-item .icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 48px;
  margin-right: 8px;
  width: 48px
}

.daily-list .daily-list-item .temp-phrase-wrapper {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 12px;
  min-width: 98px
}

.daily-list .daily-list-item .temp {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -ms-flex-negative: 0;
  align-items: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-shrink: 0
}

.daily-list .daily-list-item .temp-hi {
  -ms-flex-negative: 0;
  color: #000;
  flex-shrink: 0;
  font-size: 28px;
  font-weight: 700;
  margin-right: 4px
}

.daily-list .daily-list-item .temp-lo {
  font-size: 20px
}

.daily-list .daily-list-item .phrase {
  color: #000;
  display: none;
  font-size: 16px;
  line-height: 24px;
  margin-right: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.daily-list .no-wrap{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.daily-list p{
  margin: 0;
}
.holdReason{
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
td.company-name{
  max-width: 364px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
